import React, { useState, useEffect } from 'react' // Importing React and necessary functions

const OurSolutions = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
    <div id="our-solutions" className={`mainSection bgOneSideImage container pt-[0rem] pb-[1rem] flex flex-col lg:flex-nowrap justify-start items-start gap-8 text-[#fff]`}>
        <div className='sectionHeader w-full z-[999]'>
            <h2 className='uppercase text-white'>
                <span className='text-[#35B5A0] leading-[1]'>/ </span> Our Solutions
            </h2>
        </div>
        <div className='w-full flex items-center justify-center self-stretch lg:mt-[80px] xl:mt-[160px]'>
                <div className='p-0 pl-[15px] w-full lg:pl-0 lg:w-[70%]'>
                    <div className='p-[0] lg:p-0 mb-4 md:p-0 m-auto'>
                        <ul className='list-disc'>
                            <li className='section-paragraph mb-[25px]'>Health Outcome Tracking: Our AI-driven platforms analyse vast amounts of patient data, enabling healthcare providers to monitor health outcomes accurately and make informed decisions for personalised treatment plans.</li>
                            <li className='section-paragraph mb-[25px]'>Remote Patient Testing and Monitoring: Our innovative solutions allow patients to undergo testing and receive continuous monitoring from the comfort of their homes, reducing the need for frequent hospital visits and enabling early intervention.</li>
                            <li className='section-paragraph '>AI-Powered Health Data Platform: Our cutting-edge Cloud platform, powered by machine learning algorithms, provides accurate and reliable diagnoses, empowering healthcare professionals to deliver timely and effective treatments.</li>
                        </ul>
                    </div>
                </div>
                {/* <div className='w-full h-full'></div> */}
        </div>
    </div>
  )
}

export default OurSolutions