import React from 'react' // importing React

// Below is our Component's JSX code. Simple component to render some text that's it.
const Home = () => {
  return (
    <div className="home-landing-section z-[9] !max-w-[100%] px-[15px] h-max text-left w-full 
                        sm:ml-[0px] sm:w-[96%] !sm:max-w-[96%] sm:p-0 
                        md:ml-[0px] !md:max-w-[90%] md:w-[90%] lg:w-[94%] xl:w-[100%] xl:px-[56px]">
        <div className='w-full h-full pl-[20px] sm:px-[30px] relative z-[99] 
          before:z-[-1] before:absolute before:left-[0px] before:top-[50%] before:translate-y-[-50%] before:w-[2px] before:h-[120%] 
          before:bg-gradient-to-b from-transparent via-white/50 to-transparent'>
          <h1 id="landing-text" 
              className="text-light text-shadow-title mb-[30px] text-[24px] leading-normal lg:mt-24
              sm:mb-[20px] sm:text-[26px] sm:leading-normal
              md:text-[30px] md:font-[300]
              lg:text-[40px] lg:mb-[30px]
              xl:mb-[50px] xl:text-[50px]">
                Scaling healthcare affordability 
                <br />
                with artificial intelligence.
          </h1>
          <p className="container-sm mx-auto mb-[30px] lg:mb-[60px] text-[16px] md:text-[18px] lg:text-[20px] xl:text-[26px] leading-tight">
            At Global HealthTech (GTH), we believe that everyone deserves access to quality healthcare, regardless of their socioeconomic status or geographic location. 
            Our mission is to revolutionise healthcare delivery by leveraging cutting-edge technologies, including artificial intelligence (AI), machine learning (ML), and data analytics. 
          </p>
        </div>
    </div>
  )
}

export default Home
