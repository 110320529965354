import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'; // provider is used to integrate redux in our application. Its work is to provide store to our components.
import store from './store/store'; // store is basically where all of out state from slices(i.e actions/reducers) is kept.
import { BrowserRouter } from 'react-router-dom'; // BrowserRouter used to integrate react router in our application.
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}> {/* to provide store to our components to use state from redux store */}
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
