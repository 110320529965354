import React from 'react';
import { Gmail } from '../assets/icons/Icons';

const Footer = () => {

	return (
		<div className={`bg-[#2f2c2b] sm:bg-[#111]/80 flex justify-between items-center py-2 px-[15px] lg:px-[30px]`}>
			<div className='container !max-w-full !border-none !h-max !min-h-max flex flex-row sm:flex-row justify-between items-center'>
				<p className="footer-copyright sm:py-0 inline text-center text-[#fff] text-[12px] sm:text-[14px] whitespace-nowrap">© 2024</p>
				<p className="text-[12px] sm:text-xs text-[#fff] md:block sm:text-center tracking-[1.5px]">
					Future of health, connected
					{/* <span className="underline">coming very soon.</span>&nbsp; */}
				</p>
				<ul className='flex list-none py-2 sm:py-0'>
					<li className='ml-4' >
						<a className='cursor-pointer' href="mailto:hello@global-health.tech">
							<Gmail className="fill-blue-500"  width={20} height={20} fill='#fff'/>
						</a>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default Footer;