import React from 'react'; // Importing react
import BannerBgVideo from './BannerBgVideo'; // Importing banner background video Component which will render banner's background video
import Home from '../pages/Home' // importing Home component which renders content of Home / Banner
// import logo from '../assets/images/GHT Logo.png'
import logo from '../assets/images/GHT White.png'

// Below is our banner component. nothing fancy here just typical wrapper component with more important child components
const Banner = () => {
	// const navState = useSelector((state) => state.navSlice.navState);

	return (
		<div id="home" className="relative w-screen h-screen w-[100svw] h-[100svh] overflow-hidden">
			<div className='absolute top-[5px] sm:top-4 left-[10px] md:left-8 '>
				<a className="block z-[99999] w-24 h-24 md:w-30 md:h-30 lg:w-32 lg:h-32 xl:w-36 xl:h-36" href="/">
					<img className={`w-full h-full block nav-logo dark relative z-[99999] drop-shadow-lg rounded-full`} src={logo} alt="Global Healthtech" />
				</a>
			</div>
			<div name='home' className='header-section text-white h-screen h-[100svh] flex justify-center items-center relative'>
				<BannerBgVideo />
				<Home />
			</div>
		</div>
	)
}

export default Banner