import './App.css';
import Banner from './components/Banner';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import About from './pages/About';
import Announcement from './pages/Announcement';
import Contact from './pages/Contact';
import OurImpact from './pages/OurImpact';
import OurSolutions from './pages/OurSolutions';

function App() {
  return (
    <div className="relative overflow-x-hidden">
      <Navbar />
      <Banner />
      <About />
      <OurSolutions />
      <OurImpact />
      <Announcement />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
