import React from 'react' // importing React
import { Link } from 'react-scroll'; // Importing Link component from react-scroll to create links on click which scrolls to that section
import { Button } from '@mui/material'; // Importing button component from MUI

// Below is our Component's JSX code. Simple component to render some text that's it.
const Announcement = () => {
  return (
    <div id="announcement" className={`mainSection bgOneSideImage container pt-[0rem] pb-[1rem] flex lg:flex-nowrap justify-start items-center gap-8 text-[#fff]`}>
      <div className="home-landing-section z-[9] !max-w-[100%] sm:px-[15px] h-max text-left w-full
                          sm:ml-[0px] sm:w-[96%] !sm:max-w-[96%] sm:p-0 
                          md:ml-[0px] !md:max-w-[90%] md:w-[90%] lg:w-[66%]">
          <h2 className="text-light font-bold text-shadow-title mb-[30px] text-[1rem] leading-normal lg:mt-24
              sm:mb-[20px] sm:text-[1.2rem] sm:leading-tight 
              md:text-[20px]
              lg:text-[22px] lg:mb-[30px]
              xl:mb-[50px] xl:text-[1.8rem]">
              We are right around the corner from launching our intelligent healthcare ecosystem that will transform the lives of millions of people globally. 
              Our end-to-end connected platforms bring together <span className="relative font-normal italic text-[#ccc] drop-shadow-lg">cutting-edge IoT devices</span>,&nbsp;
              <span className="relative font-normal italic text-[#ccc] drop-shadow-lg">real-time data analytics</span>&nbsp; and&nbsp;
              <span className="relative font-normal italic text-[#ccc] drop-shadow-lg">AI capabilities</span>&nbsp;
              to significantly 
              improve access and quality of care.
          </h2>
          <p className="container-sm md:font-[200] mx-auto mb-[30px] lg:mb-[60px] text-[14px] sm:text-[16px] md:text-[16px] lg:text-[20px] xl:text-[26px] leading-tight">
              We are currently seeking collaboration with forward-thinking healthcare companies, med-tech leaders, 
              public health systems and investment partners who share our purpose of revolutionising health outcomes. 
          </p>
          <Link className="nav-link" role="button" to="contact" offset={0} smooth={true} duration={400}>
            <Button className='!capitalize !pt-[5px] !pb-[5px] leading-normal md:leading-none !text-[10px] sm:!text-[12px] md:!text-[12px] !font-medium' 
              variant="contained">
              Get in touch to explore how we can drive change together.
            </Button>
          </Link>
          <p className="container-sm hidden md:block mx-auto relative leading-6 mt-20 md:mt-8 lg:mt-12 md:text-[14px] lg:text-[18px] xl:text-[20px]">
              " The future of healthcare powered by our intelligent platforms is&nbsp;
              <span className="!font-[600]">coming very soon</span>.&nbsp;<br />
              We can't wait for you to be a part of our journey of purpose and impact. "
          </p>
          <p className="container-sm md:hidden mx-auto lg:max-w-[80%] leading-normal text-[12px] mt-[30px] sm:text-[14px]">
              The future of healthcare powered by our intelligent platforms is&nbsp;
              <span className="!font-[600]">coming very soon</span>.&nbsp;
              We can't wait for you to be a part of our journey of purpose and impact.
          </p>
      </div>
    </div>
  )
}

export default Announcement