import React, { useState, useEffect } from 'react' // Importing React and necessary functions


const About = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
    <div id="about" className={`mainSection bgOneSideImage container pt-[0rem] pb-[1rem] flex flex-col lg:flex-nowrap justify-start items-start gap-8 text-[#fff]`}>
        <div className='sectionHeader w-full z-[999]'>
            <h2 className='uppercase text-white'>
                <span className=''>/ </span> About Us
            </h2>
        </div>
        <div className='w-full flex items-center justify-center self-stretch lg:pl-[40px] mt-[20px] lg:mt-[100px] xl:mt-[160px]'>
          <div className='p-0 w-[100%]'>
              <div className='p-[0] lg:p-0 mb-4 md:p-0 m-auto'>
                  <p className='section-paragraph'>
                      Founded in 2024, GTH is a digital health-tech enablement venture headquartered in San Francisco, with a global network spanning Asia, Europe, and Africa. 
                      We specialise in deploying advanced platforms for health outcome tracking, remote patient testing/monitoring, and AI-powered patient treatment.
                  </p>
                  <div className='w-full h-full py-[10px] lg:px-[30px] lg:py-[18px] relative z-[99] mt-[15px] lg:mt-[40px]
                      before:z-[-1] before:absolute before:left-[50%] before:top-[0] before:translate-x-[-50%] before:w-[70%] before:h-[2px] before:bg-gradient-to-r from-transparent via-white/80 to-transparent
                      after:z-[-1] after:absolute after:left-[50%] after:bottom-[0] after:translate-x-[-50%] after:w-[70%] after:h-[2px] after:bg-gradient-to-r from-transparent via-white/80 to-transparent
                      '>
                      <h2 className='section-tagline'>
                          embracing artificial intelligence as a catalyst for sustainable healthcare development and success
                      </h2>
                  </div>
              </div>
          </div>
          <div className='hidden sm:block sm:w-[50%] md:w-full h-full'></div>
        </div>
    </div>
  )
}

export default About